@charset 'UTF-8';

@keyframes spin {
    from {
        transform: scale(1) rotate(0deg);
    }

    to {
        transform: scale(1) rotate(360deg);
    }
}

@keyframes highlight {
    from {
        @include alpha-property(background, $yellow, 0.5);
    }

    to {
        background: none;
    }
}

@mixin fade-in {
    animation-duration: 0.3s;
    animation-iteration-count: 1;
    animation-name: fadeInOpacity;
    animation-timing-function: ease-in;
    opacity: 1;
}

@keyframes fadeInOpacity {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
