@charset "utf-8";

.status-wrapper {
    position: relative;
    display: inline-block;
    height: 32px;
    align-self: center;

    .sidebar--left & {
        width: 36px;
        height: 36px;

        .status {
            right: -6px;
            bottom: -5px;
            width: 19px;
            height: 19px;

            &.status-edit {
                .fa {
                    top: 4px;
                }
            }

            .fa {
                position: relative;
                top: 2px;
            }

            .icon__container {
                &::after {
                    position: absolute;
                    top: 4px;
                    left: 4px;
                    width: 10px;
                    height: 10px;
                    border-radius: 20px;
                    content: '';
                }
            }

            svg {
                position: relative;
                z-index: 1;
                top: 0;
                left: 0;
                width: 13px;
                height: 13px;
                max-height: initial;

                &.offline--icon,
                .offline--icon {
                    fill: var(--offline-indicator);
                }
            }
        }
    }

    .status {
        position: absolute;
        top: auto;
        right: -3px;
        bottom: -4px;
        width: 15px;
        height: 15px;
        margin: 0;
        background: var(--center-channel-bg);
        border-radius: 100px;
        line-height: 0;
        visibility: visible;

        .modal & {
            bottom: -6px;
        }

        &.status-edit {
            text-align: center;
            visibility: hidden;

            i {
                position: relative;
                top: 4px;
                font-size: 10px;
                opacity: 1;
            }
        }

        svg {
            position: relative;
            top: 2px;
            max-height: 11px;
        }

        .dnd--icon {
            fill: var(--dnd-indicator);
        }

        .online--icon {
            fill: var(--online-indicator);
        }

        .away--icon {
            fill: var(--away-indicator);
        }

        .offline--icon {
            fill: var(--offline-indicator);
        }
    }
}

.status-selector {
    .status {
        body.enable-animations & {
            transition-delay: 0s;
            transition-duration: $transition-quick;
            transition-property: opacity, visibility;
            transition-timing-function: ease-out, step-start;
        }

        opacity: 1;
        visibility: visible;
    }

    .status-edit {
        opacity: 0;
        visibility: hidden;
    }

    &:hover {
        .status {
            opacity: 0;
            visibility: hidden;
        }

        .status-edit {
            opacity: 1;
            visibility: visible;
        }
    }
}

.status {
    position: relative;
    top: 2px;
    display: inline-block;
    width: 15px;
    margin: 0 7px 0 1px;

    &.status--group {
        top: 0;
        width: 15px;
        height: 15px;
        margin: 0 9px 0 -1px;
        border-radius: 2px;
        font-size: 10px;
        font-weight: 600;
        line-height: 14px;
        text-align: center;
    }

    svg {
        max-height: 14px;
        backface-visibility: hidden;
    }

    i,
    path,
    ellipse {
        &.online--icon,
        &.away--icon,
        &.dnd--icon {
            opacity: 1;
        }
    }

    .fa-lock {
        margin-left: 1px;
    }

    .fa-globe {
        position: relative;
        top: -1px;
    }
}

.status-dropdown-menu {
    .online--icon > svg {
        fill: var(--online-indicator);
    }

    .away--icon > svg {
        fill: var(--away-indicator);
    }

    .dnd--icon > svg {
        fill: var(--dnd-indicator);
    }

    .offline--icon {
        fill: var(--offline-indicator);
    }
}

.app__body {
    .status {
        &.status--online {
            color: var(--online-indicator);
        }

        &.status--away {
            color: var(--away-indicator);
        }

        .dnd--icon {
            fill: var(--dnd-indicator);
        }

        .online--icon {
            fill: var(--online-indicator);
        }

        .away--icon {
            fill: var(--away-indicator);
        }

        .offline--icon {
            fill: var(--offline-indicator);
        }
    }

    .sidebar--left {
        .status {
            .offline--icon {
                fill: var(--offline-indicator);
            }
        }
    }
}
