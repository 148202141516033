@charset 'UTF-8';

.mention {
    position: relative;
    z-index: 10;
    padding-bottom: 2px;
    background: $primary-color;
    border-radius: 3px;
    color: $white;
}

.badge {
    display: inline-block;
    min-width: 10px;
    padding: 3px 7px;
    background-color: #777;
    border-radius: 10px;
    color: #fff;
    font-size: 12px;
    font-weight: bold;
    line-height: 1;
    text-align: center;
    vertical-align: middle;
    white-space: nowrap;
}

.mention--highlight {
    padding: 0 1px;
    background-color: $yellow;
    border-radius: 4px;

    .mention--highlight {
        padding: 0;
    }
}

.group-mention-link {
    color: var(--link-color);
}
